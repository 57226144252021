import { NotificationManager } from "react-notifications";
import { CreateCommunityUser, JSON_HEADER, UpdateCommunityDoctor } from "./Const";

let memberId = '';

const formatUserData = (data) => {
    const {name, email, mobile, password, city, state, country, zipcode, message, userType, _id, certificate} = data;
    
    let accountType = 'Users';
    let certType = '';
    let visibility = 1;
    const firstname = name.split(' ').slice(0, -1).join(' ');
    const lastname = name.split(' ').slice(-1).join(' ');
    
    if (userType === 1) {
      accountType = "Doctors"
      certType = certificate;
      //visibility = 0 //removed visibility setting for Doctors, since newest API version doesn't support this value - Build 36
    };
    
    return  data = {
      account: {
        username: email, //username is just a string, so we can use name
        email,
        visibility,
        status: 1,
        tagsField : [
            accountType, certType //this doesn't actually set Group, it only adds a tag. Group membership is a separate call (see updateCommunityDoctor.js)
        ], //added certType to automatically add certification as a tag for Doctors
        authclient: "local",
        //authclient: "YBS",
        authclient_id: _id,
      },
      profile: {
        firstname, 
        lastname,
        zip: zipcode, 
        city,
        state,
        country,
        about: message,
        mobile,
      },
      password: {
        newPassword: password,
        mustChangePassword: false
      }
    };
}

export const handleNewCommunityUser = (data) => {
    fetch(CreateCommunityUser, {
      method: "POST",
      headers: JSON_HEADER,
      body: JSON.stringify(formatUserData(data)),
    }).then((res) => {
      if (res.ok) {
        res.json()
        .then(res => res)
          memberId = res.id
        .catch((err) => {
          NotificationManager.error("Error creating your account in the Community site.")
        })
      }
    });
    return memberId;
  };

  export const handleCommunityDoctor = (data) => {
    fetch(UpdateCommunityDoctor, {
      method: "POST",
      headers: JSON_HEADER,
      body: JSON.stringify(data),
    }).then((res) => {
      if (res.ok) {
        res.json()
        .then(res => res)
        .catch((err) => {
          NotificationManager.error("Error updating Community Doctor")
        })
      }
    })
  };