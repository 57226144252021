import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux'
import store from './Redux/Store/Store';

import ReactGA from 'react-ga4';

//comement this out for dev, uncomment for prod
//ReactGA.initialize('G-QQMMDD2743')
// Initialize Google Analytics with the tracking ID from environment variables
const gaTrackingID = process.env.REACT_APP_GA_TRACKING_ID;
if (gaTrackingID) {
  ReactGA.initialize(gaTrackingID);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);