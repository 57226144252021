// FAQIframe.js

import { Pix } from '@mui/icons-material';
import React from 'react';

const FAQIframe = () => {
  return (
    <iframe
      src="/FAQPage.html" // Specify the path to your FAQ.html file
      title="FAQ"
      width="75%"
      height="675vh" // Set the height according to your preference
      frameBorder="0"
      scrolling="auto"
      loading="lazy"
      style={{
        border: '2px solid #ccc', // Border style
        backgroundColor: '#f5f5f5', // Background color
        marginLeft: '15%',
      }}
      id="faqIframe"
    />
  );
};

export default FAQIframe;
